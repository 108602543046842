import {commonStoreFetch, commonStoreSave, commonStoreDelete, commonTableFetch} from "@/store/common-functions";

function getDefaultState() {
    return {
        transfersTable: {},
        transfersDebitTable: {},
        transfer: {},
        bank_accounts: [],
    }
}

const getters = {
    getTransfersTable: state => state.transfersTable,
    getTransfersDebitTable: state => state.transfersDebitTable,
    getTransfer: state => state.transfer,
    getBankAccounts: state => state.bank_accounts,
}

const actions = {
    fetchBankAccounts({commit, _}, params) {
        return commonStoreFetch(commit, '/bank/accounts', 'setBankAccounts')
    },
    fetchTransfers({commit, _}, params) {
        return commonTableFetch(commit, '/bank-transfers', 'setTransfersTable', params)
    },
    fetchDebitTransfers({commit, _}, params) {
        return commonTableFetch(commit, '/bank-transfers-debit', 'setTransfersDebitTable', params)
    },
    fetchTransfer({commit, _}, params) {
        return commonStoreFetch(commit, '/bank-transfers/' + params.id, 'setTransfer')
    },
    mapTransactions({commit, _}, data) {
        return commonStoreSave('/bank-transfers/mass-auto-assign', data)
    },
    assign({commit, _}, params) {
        return commonStoreSave('/bank-transfers/assign', params)
    },
    createFromIncoming({commit, _}, params) {
        return commonStoreSave('/bank-transfers/create-from-incoming', params)
    },
    create({commit, _}, params) {
        return commonStoreSave('/bank-transfers/create', params)
    },
    assignToIncomingInvoice({commit, _}, params) {
        return commonStoreSave('/bank-transfers/assign-to-incoming-invoice', params)
    },
    assignToOffering({commit, _}, params) {
        return commonStoreSave('/bank-transfers/assign-to-offering', params)
    },
    unlink({commit, _}, params) {
        return commonStoreSave('/bank-transfers/unlink', params)
    },
    unlinkDebit({commit, _}, params) {
        return commonStoreSave('/bank-transfers/unlink-debit', params)
    },
    deleteCredit({commit, _}, params) {
        return commonStoreSave('/bank-transfers/delete-credit', params)
    },
    deleteDebit({commit, _}, params) {
        return commonStoreSave('/bank-transfers/delete-debit', params)
    },
    delete(_, id) {
        return commonStoreDelete('/bank-transfers/delete/' + id)
    },
}

const mutations = {
    setTransfersTable(state, data) {
        state.transfersTable = data
    },
    setTransfersDebitTable(state, data) {
        state.transfersDebitTable = data
    },
    setTransfer(state, data) {
        state.transfer = data
    },
    setBankAccounts(state, data) {
        state.bank_accounts = data
    },
}

export default {
    namespaced: true,
    state: getDefaultState,
    getters,
    actions,
    mutations
}