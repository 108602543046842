<template>
    <div>
        <b-row class="table-actions content-width">
            <b-col>
                <b-row>
                    <b-col cols="5">
                        <strong v-if="title">{{ $t(title) }}</strong>
                        <slot name="top-actions"></slot>
                    </b-col>
                    <b-col cols="7">
                        <table-pagination v-model="source"
                                          :refresh="source.refreshTable"
                                          :tableUniqKey="source.tableUniqKey"
                                          :table="$attrs.tableName"
                                          :table-format="tableFormat"
                                          :filter="$attrs.filter"
                                          :selected="$attrs.selected"
                                          :additional-options="$attrs['additional-options'] ||null"
                                          :disable-pagination="disablePagination"
                                          :actions-list="actionsList"
                        ></table-pagination>
                    </b-col>
                </b-row>
                <slot name="top-block"></slot>

                <table-selected-filters v-model="tableFilters"
                                        :source="source"
                />
            </b-col>
        </b-row>

        <b-table id="table"
                 ref="table"
                 :class="sticky ? 'zw-sticky' : ''"
                 :responsive="$attrs['sticky-header'] ? false : true"
                 striped
                 hover
                 sort-icon-right
                 custom-foot
                 head-row-variant="info"
                 foot-row-variant="info"
                 :sort-by.sync="source.tableOptions.sortBy"
                 :sort-desc.sync="source.tableOptions.sortDesc"
                 :current-page="source.tableOptions.currentPage"
                 label-sort-clear=""
                 :per-page="source.tableOptions.perPage"
                 v-bind="$attrs"
                 :filter="$attrs.filter"
                 v-on="$listeners"
                 @refreshed="tableLoaded"
        >
            <template #table-busy>
                <div class="text-left my-2">
                    <b-spinner class="align-middle mr-2"></b-spinner>
                    <strong>Loading...</strong>

                    <b-button class="ml-2"
                              variant="danger"
                              @click="cancelRequest"
                    >{{ $t('common.title.cancel') }}
                    </b-button>
                </div>
            </template>

            <template #head()="data">
                <span v-for="group in data.field.groups">[{{ group }}]</span>
                <span v-if="data.field.title"
                      class="thead_span"
                      :data-key="data.field.key"
                      :data-isPrice="'is_price_'+data.field.is_price"
                      style="white-space: nowrap;"
                ><font-awesome-icon class="mr-1" v-if="data.field.filter.type=='date'"
                                    icon="calendar"></font-awesome-icon>{{ data.field.title }}</span>
                <span v-else
                      :class="'thead_span'+' thead_span_type_'+data.field.filter.type"
                      :data-isPrice="'is_price_'+data.field.is_price"
                      :data-key="data.field.key"
                      style="white-space: nowrap;"
                ><font-awesome-icon class="mr-1"
                                    v-if="data.field.filter.type=='date'"
                                    icon="calendar"/>{{ $t(data.field.translation) }}</span>
                <input type="hidden" name="name" :value="data.field.key">
                <input type="hidden" name="width" :value="data.field.width || '215px'">

                <template v-if="data.field.sortable_column && data.field.key != 'selected'">
                    <b-icon :icon="'sort-down'"
                            class="ml-1"
                            :style="'cursor:pointer;color: '+((data.field.key==source.tableOptions.sortBy && source.tableOptions.sortDesc) ? 'black' : 'gray')"
                            @click="sort(data.field.key, true)"
                    />
                    <b-icon :icon="'sort-up-alt'"
                            class="ml-1"
                            :style="'cursor:pointer;color: '+((data.field.key==source.tableOptions.sortBy && !source.tableOptions.sortDesc) ? 'black' : 'gray')"
                            @click="sort(data.field.key, false)"
                    />
                </template>
            </template>

            <template v-if="!disableFilter" #top-row="row">
                <table-filters v-for="column in source.fields"
                               :key="column.key"
                               v-model="tableFilters"
                               :column="column"
                               @select="source.selectAll"
                               @selectSource="source.selectSource"
                               :tableSource="source"
                               :attributes="$attrs"
                               :tableOptions="source.tableOptions"
                               :sourceOptions="sourceOptions()"
                               @start-filtering="startFiltering"
                ></table-filters>
            </template>

            <template #cell(selected)="data">
                <div>
                    <b-form-checkbox :key="data.item.id"
                                     @change="source.selectRow($event, data.item)"
                                     :checked="$attrs.selected.find(item => item.id==data.item.id) ? true: false"
                                     v-if="!['all','filtered'].includes($attrs.selectedSource)"
                    ></b-form-checkbox>
                </div>
            </template>

            <template #custom-foot="data">
                <tr v-if="source.tableOptions.totalRows && typeof totals != 'undefined' && Object.keys(totals).length"
                    class="first_tfoot_tr"
                >
                    <th v-for="(column,index) in data.fields">
                        <div class="text-left position-absolute" v-if="index == 0">
                            {{ $t('export.source.current_page') }}:
                        </div>
                        <div class="text-right" v-if="totals && typeof totals[column.key] != 'undefined'">
                            {{ totals[column.key] | priceFormat }}
                            &euro;
                            <span v-if="selectedTotals[column.key] >= 0 && source.selected.length > 0"> ({{
                                    selectedTotals[column.key] | priceFormat
                                }} &euro;)</span>
                        </div>
                    </th>
                </tr>
                <tr v-else>
                    <th colspan="100%"></th>
                </tr>
                <tr v-if="source.tableOptions.totalRows && typeof source.tableOptions.grandTotal != 'undefined' && Object.keys(source.tableOptions.grandTotal).length"
                    class="second_tfoot_tr"
                >
                    <th v-for="(column,index) in data.fields" class="bg-secondary text-dark">
                        <div class="text-left position-absolute" v-if="index == 0">
                            {{ $t('export.source.filtered') }}:
                        </div>
                        <div class="text-right" v-if="source.tableOptions.grandTotal['sum_' + column.key]">
                            {{ source.tableOptions.grandTotal['sum_' + column.key] | priceFormat }}
                            &euro;
                        </div>
                    </th>
                </tr>
            </template>


            <template v-for="field in customFields"
                      #[`cell(`+field.key+`)`]="row"
            >
                <template v-if="field.field_type=='date'">
                    <template v-if="row.item[field.key]">
                        {{ row.item[field.key] | formatDate('DD.MM.YYYY') }}
                    </template>
                    <template v-else>-</template>
                </template>
                <template v-else-if="field.field_type=='select'">
                    <template v-if="row.item[field.key]">
                        <CBadge color="light">{{ row.item[field.key] }}</CBadge>
                    </template>
                    <template v-else>-</template>
                </template>
                <template v-else>{{ row.item[field.key] }}</template>
            </template>

            <template v-for="(component, slotName)  in customTemplateColumns(baseTable)"
                      #[`cell(`+slotName+`)`]="row"
            >
                <span :title="getTitle(row)"
                      :class="(source.priceColumns && Object.keys(source.priceColumns).includes(slotName)) ? 'text-right':''"
                >
                    <component v-bind:is="component"
                               v-bind="columnParams"
                               :row="getRowData(row, slotName)"
                               :refresh-table="refresh"
                               :column="slotName"
                    ></component>
                </span>
            </template>

            <template v-for="(_, scopedSlotName) in $scopedSlots" #[scopedSlotName]="slotData">
                <span v-if="scopedSlotName != 'custom-foot'" :title="getTitle(slotData)">
                    <slot :name="scopedSlotName" v-bind="slotData"/>
                </span>
                <slot v-else :name="scopedSlotName" v-bind="slotData"/>
            </template>

            <template v-for="(_, slotName) in $slots" #[slotName]>
                <slot :name="slotName"/>
            </template>

            <template #cell()="data">
                <span :title="getTitle(data)">
                    {{ data.item[data.field.key] }}
                </span>
            </template>
        </b-table>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import TableSelectedFilters from "@/bundles/erika_common_bundle/TableSelectedFilters";

export default {
    name: 'ZwTable',
    components: {TableSelectedFilters},
    data() {
        return {
            tableFilters: {},
        }
    },
    props: {
        'customSource': {
            default: null,
        },
        'sticky': {
            type: Boolean,
            default: true
        },
        'disableFilter': {
            type: Boolean,
            default: false
        },
        'disablePagination': {
            type: Boolean,
            default: false
        },
        'tableFormat': {
            type: String,
            default: null
        },
        'actionsList': {
            type: [Object, Array],
        },
        'title': {
            type: String,
        },
        'baseTable': {
            type: String,
        },
        'columnParams': {
            type: [Object],
        },
    },
    created() {
        this.shown()
        this.setTableFilters()
    },
    methods: {
        ...mapGetters('Export', ['getSources']),
        ...mapGetters(['getLastFetchToken']),
        getRowData(row, slotName) {
            let prefix = slotName.split('.').slice(0, -1).join('.') + '.'
            let newItem = {}

            if (prefix.length > 1) {
                Object.keys(row.item).forEach(key => {
                    if (key.startsWith(prefix)) {
                        newItem[key.replace(prefix, '')] = row.item[key]
                    }
                })
                row.item = newItem
            }

            return row
        },
        sort(key, desc) {
            this.source.tableOptions.sortBy = key
            this.source.tableOptions.sortDesc = desc

            if (this.source.saveFilters) {
                this.$set(this.source.filter, 'filterChanged', true)
            }
        },
        getTitle(data) {
            let title = data.field ? (data.item[data.field.key] ?? '') : '';
            if (typeof title == "object" || typeof title == "array") {
                title = title['name'] ?? '';
            }
            return title;
        },
        setTableFilters() {
            Object.keys(this.source.filter).forEach(key => {
                this.$set(this.tableFilters, key, this.source.filter[key])
            })
        },
        shown() {
        },
        startFiltering(value) {
            this.$emit('update:filter', JSON.parse(JSON.stringify(value)))
        },
        sourceOptions: function () {
            let options = [{
                value: 'none',
                text: this.$t('export.source.none')
            }]
            this.getSources().forEach(key => {
                let postFix = ''
                if (key == 'filtered') {
                    postFix = ' (' + this.source.tableOptions.totalRows + ')'
                }
                if (key == 'selected') {
                    postFix = ' (' + this.$attrs.selected.length + ')'
                }
                if (key == 'current_page') {
                    postFix = ' (' + this.source.allItems.filter(item => {
                        return typeof item.selectable == 'undefined' || item.selectable
                    }).length + ')'
                }
                options.push({
                    value: key,
                    text: this.$t('export.source.' + key) + postFix,
                })
            })
            return options
        },
        refresh() {
            this.$refs.table.refresh()
        },
        initResize() {
            let thElm;
            let startOffset;

            Array.prototype.forEach.call(
                document.querySelectorAll("table thead th"),
                function (th) {
                    let grip = document.createElement('div');
                    grip.innerHTML = "&nbsp;";

                    grip.style.top = 0;
                    grip.style.right = 0;
                    grip.style.bottom = 0;
                    grip.style.width = '5px';
                    grip.style.position = 'absolute';
                    grip.style.cursor = 'col-resize';
                    grip.style.borderLeft = 'dotted 1px gray';
                    grip.style.borderRight = 'dotted 1px gray';
                    grip.style.marginTop = '2px';
                    grip.style.marginBottom = '2px';

                    const widthInput = th.querySelector('input[name="width"]')
                    const columnName = th.querySelector('input[name="name"]') ? th.querySelector('input[name="name"]').value : ''

                    let defaultMinWidth = (columnName == 'selected' || columnName == 'id') ? '100px' : '215px'
                    let width = widthInput ? widthInput.value : defaultMinWidth

                    th.style.minWidth = width
                    th.style.maxWidth = width

                    let thSpan = th.querySelector('.thead_span')
                    if (thSpan) {
                        let minSpanWidth = 35;
                        if (th.querySelector('svg') !== null) {
                            minSpanWidth += 30;
                        }
                        if (parseInt(width) <= minSpanWidth) {
                            thSpan.style.maxWidth = '12px';
                        } else {
                            thSpan.style.maxWidth = null;
                        }
                    }

                    grip.addEventListener('mousedown', function (e) {
                        thElm = th;
                        startOffset = th.offsetWidth - e.pageX;
                        e.preventDefault()
                    });

                    grip.addEventListener('click', function (e) {
                        e.stopPropagation()
                    })

                    th.appendChild(grip);
                });

            setTimeout(() => {
                this.resizeTbody();
            }, 500);

            document.addEventListener('mousemove', function (e) {
                if (thElm) {
                    let width = startOffset + e.pageX

                    let min = 30;
                    let minSpanWidth = 35;
                    if (thElm.querySelector('svg') !== null) {
                        min += 30;
                        minSpanWidth += 30;
                    }

                    let thTypeDate = thElm.querySelector('.thead_span_type_date')
                    if (thTypeDate) {
                        min += 170;
                        minSpanWidth += 160;
                    }

                    if (width < min) {
                        width = min
                    }

                    let thSpan = thElm.querySelector('.thead_span')
                    if (thSpan) {
                        if (width <= minSpanWidth) {
                            thSpan.style.maxWidth = '12px';
                        } else {
                            thSpan.style.maxWidth = null;
                        }
                    }

                    width += 'px'

                    $(thElm).closest('table').find('tr').each(function (index) {
                        let td = $(this).find('td:eq(' + $(thElm).index() + ')');
                        if (td) {
                            td.css({'max-width': width});
                        }
                    });
                    thElm.style.minWidth = width;
                    thElm.style.maxWidth = width;
                }
            });


            document.addEventListener('mouseup', (e) => {
                if (thElm) {
                    this.saveColumnWidth(this.$attrs['tableName'], thElm.querySelector('input[name="name"]').value, thElm.style.minWidth)
                    e.preventDefault()
                }
                thElm = undefined;
            });
        },
        resizeTbody() {
            Array.prototype.forEach.call(
                document.querySelectorAll("table"),
                function (table, index) {
                    let indexes = [];
                    let heights = [];
                    let classes = [];
                    let isPrices = [];
                    $(table).find('tbody td, tbody th').each(function (ind, td) {
                        let index = $(td).index()
                        if (!indexes.includes(index)) {
                            indexes.push(index);
                            let th = $(td).closest('table').find('th:eq(' + index + ')');
                            heights.push(th.css('min-width'))
                            let newClass = $(th).find('.thead_span').attr('data-key');
                            classes.push(newClass)
                            isPrices.push($(th).find('.thead_span').attr('data-isprice'))
                        }
                        td.style.maxWidth = heights[index];
                        td.classList.add('td_table_' + classes[index]);
                        td.classList.add(isPrices[index]);
                    });
                });
        },
        saveColumnWidth(table, column, width) {
            axios.post(window.apiUrl + '/save-table-column-width', {
                table,
                table_format: this.tableFormat,
                column,
                width: width,
            }, {'skip_loading': true})
            this.resizeTbody()
        },
        tableLoaded() {
            this.initResize()
        },
        calcTotal(items) {
            let result = {}
            this.$attrs.fields.forEach(column => {
                if (column.is_price) {
                    let columnTotal = 0
                    items.forEach(item => {
                        let value = item[column.key] || null;
                        if (value && typeof value === 'object') {
                            value = value.amount || null
                        }
                        columnTotal += parseFloat(value == null ? 0 : value)
                    })
                    result[column.key] = columnTotal
                }
            })
            return result
        },
        cancelRequest() {
            const cancelTokenSource = this.getLastFetchToken()
            cancelTokenSource.cancel('Operation canceled')
        },
        customTemplateColumns(table) {
            return {
                ...this.$root.getColumns('common'),
                ...this.$root.getColumns(table)
            }
        },
    },
    computed: {
        source() {
            return this.customSource || this.$parent
        },
        selectedTotals: function () {
            return this.calcTotal(this.source.selected)
        },
        totals: function () {
            return this.calcTotal(this.source.allItems)
        },
        customFields() {
            return this.$attrs.fields.filter(field => {
                return field.type && field.type == 'custom'
            })
        },
        customDateFields() {
            return this.$attrs.fields.filter(field => {
                return field.type && field.type == 'custom' && field.field_type == 'date'
            })
        },
        customSelectFields() {
            return this.$attrs.fields.filter(field => {
                return field.type && field.type == 'custom' && field.field_type == 'select'
            })
        }
    },
    watch: {
        $attrs: {
            handler: function (val) {
                this.setTableFilters()
            },
            deep: true
        },
    }
}
</script>
<style>
.table tfoot th {
    padding: 0.25rem 0.5rem;
}
</style>