<template>
    <ValidationProvider :name="name" :rules="validate" v-slot="validationContext">
        <b-form-group>
            <template v-slot:label>
                <template v-if="label">{{ label }}</template>
                <template v-else>
                    {{ $t(labelPrefix + name) }}
                </template>
                <span v-if="validationContext.required" class="text-danger">*</span>
            </template>
            <b-input-group>
                <b-input-group-prepend>
                    <b-form-select v-model="code"
                                   :class="'form-control form-control-'+size"
                                   :size="size"
                                   :options="optionsList"
                    ></b-form-select>
                </b-input-group-prepend>
                <b-form-input v-model="phone"
                              :placeholder="placeholder"
                              :state="getValidationState(validationContext)"
                              v-bind="$props"
                              :readonly="readonly"
                              :autocomplete="autocomplete"
                              v-on="$listeners"
                              :size="size"
                ></b-form-input>
            </b-input-group>
            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
        </b-form-group>
    </ValidationProvider>
</template>

<script>
import validateMixin from '../mixins/validate'

export default {
    props: {
        'name': {
            type: String,
            required: true
        },
        'value': [String, Number],
        'validate': [String, Object],
        'icon': [String, Array],
        'type': String,
        'min': [String, Number],
        'step': [String, Number],
        'label': String,
        'labelHtml': String,
        'placeholder': String,
        'size': {
            type: String,
            default: 'lg'
        },
        'disableLabel': {
            type: Boolean,
            default: false
        },
        'readonly': {
            type: Boolean,
            default: false
        },
        'autocomplete': {
            type: String,
            default: 'new-password'
        },
        'labelPrefix': {
            type: String,
            default: 'common.label.'
        },
    },
    data() {
        return {
            code: '49',
            phone: null,
            options: {
                'US': '1',
                'AT': '43',
                'BE': '32',
                'BG': '359',
                'HR': '385',
                'CY': '357',
                'CZ': '420',
                'DK': '45',
                'EE': '372',
                'FI': '358',
                'FR': '33',
                'DE': '49',
                'GR': '30',
                'HU': '36',
                'IE': '353',
                'IT': '39',
                'LV': '371',
                'LT': '370',
                'LU': '352',
                'MT': '356',
                'NL': '31',
                'PL': '48',
                'PT': '351',
                'RO': '40',
                'SK': '421',
                'SI': '386',
                'ES': '34',
                'SE': '46',
                'AL': '355',
                'AD': '376',
                'AM': '374',
                'BA': '387',
                'FO': '298',
                'GE': '995',
                'GI': '350',
                'IS': '354',
                'IM': '44',
                'XK': '381',
                'LI': '423',
                'MK': '389',
                'MD': '373',
                'MC': '377',
                'ME': '382',
                'NO': '47',
                'SM': '378',
                'RS': '381',
                'CH': '41',
                'TR': '90',
                'UA': '380',
                'GB': '44',
                'VA': '39',
            }
        }
    },
    mixins: [validateMixin],
    computed: {
        optionsList() {
            let options = [];
            Object.keys(this.options).forEach(key => {
                options.push({
                    value: this.options[key],
                    text: key + ' +' + this.options[key],
                })
            })

            return options
        },
    },
    mounted() {
        if (this.phone) {
            this.$emit('input', '+' + this.code + this.phone);
        }
    },
    watch: {
        value: {
            immediate: true,
            handler(val) {
                let phone = val
                if (this.value && this.value.startsWith('+')) {
                    Object.keys(this.options).forEach(key => {
                        if (this.value.startsWith('+' + this.options[key])) {
                            this.code = this.options[key]
                            phone = this.value.replace('+' + this.options[key], '')
                        }
                    })
                }
                this.phone = phone
            }
        },
        phone: function (val) {
            this.$emit('input', '+' + this.code + val);
        },
        code: function (val) {
            this.$emit('input', '+' + val + this.phone);
        },
    }
}
</script>

<style>
.vue__time-picker {
    width: 100%;
}

.vue__time-picker input.display-time {
    width: 100%;
}

.vue__time-picker input.display-time {
    height: calc(1.5em + 0.75rem + 2px);
}

</style>