import {
    buildFormData,
    commonStoreFetch,
    commonStoreDelete, commonTableFetch, commonStoreSave
} from "@/store/common-functions";

function getDefaultState() {
    return {
        salesTable: [],
        salesAttachments: [],
        processes: [],
    }
}

const getters = {
    getSalesTable: state => state.salesTable,
    getSalesAttachments: state => state.salesAttachments,
    getProcesses: state => state.processes,
}

const actions = {
    fetchSales({commit, _}, params) {
        return commonTableFetch(commit, '/sales', 'setSalesTable', params)
    },
    fetchCustomerSales({commit, _}, params) {
        return commonTableFetch(commit, '/sales', 'setSalesTable', params, {
            tableName: params.tableName,
        })
    },
    fetchProcesses({commit, _}, customerId) {
        return new Promise((resolve, reject) => {
            window.axios.get(window.apiUrl + '/sales/processes/' + customerId, {'skip_loading': true})
                .then(response => {
                    commit('setProcesses', response.data)
                    resolve()
                }, () => {
                    reject()
                })
        })
    },
    reSyncOrders({commit, _}, data) {
        return commonStoreSave('/sales/re-sync-orders', data)
    },
    reGenerateInvoices({commit, _}, data) {
        return commonStoreSave('/sales/re-generate-invoices', data)
    },
    deleteSales({commit, _}, data) {
        return commonStoreSave('/offering/hard-delete', data)
    },
    saveArticlesMap({commit}, insurance) {
        return new Promise((resolve, reject) => {
            const formData = new FormData();

            Object.entries(insurance).forEach(([key, value]) => {
                formData.append(key, value);
            });

            window.axios.post(window.apiUrl + '/sales/upload-amazon-report', formData, {
                'skip_loading': true,
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
            })
                .then(response => {
                    resolve(response);
                })
                .catch((error) => {
                    if (error.response && error.response.data && error.response.data.errors) {
                        reject(error.response.data.errors)
                    }
                })
        })
    },
    fetchSalesAttachments({commit, _}, params) {
        return commonTableFetch(commit, '/sales/attachments', 'setSalesAttachments', params)
    },
    saveAttachments({commit}, params) {
        return new Promise((resolve, reject) => {
            const formData = new FormData();
            buildFormData(formData, params)

            window.axios.post(window.apiUrl + '/sales/attachments-save', formData, {
                'skip_loading': true,
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
            })
                .then(response => {
                    resolve(response);
                })
                .catch((error) => {
                    if (error.response && error.response.data && error.response.data.errors) {
                        reject(error.response.data.errors)
                    }
                })
        })
    },
    setDocsAttachment({commit}, params) {
        return new Promise((resolve, reject) => {
            const formData = new FormData();
            buildFormData(formData, params)

            window.axios.post(window.apiUrl + '/sales/attachments-save-docs', formData, {
                'skip_loading': true,
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
            })
                .then(response => {
                    resolve(response);
                })
                .catch((error) => {
                    if (error.response && error.response.data && error.response.data.errors) {
                        reject(error.response.data.errors)
                    }
                })
        })
    },
    deleteAttachment(_, id) {
        return commonStoreDelete('/sales/delete-attachment/' + id)
    },
}

const mutations = {
    setSalesTable(state, data) {
        state.salesTable = data
    },
    setSalesAttachments(state, attachments) {
        state.salesAttachments = attachments
    },
    setProcesses(state, processes) {
        state.processes = processes.data
    }
}

export default {
    namespaced: true,
    state: getDefaultState,
    getters,
    actions,
    mutations
}